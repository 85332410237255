@font-face {
  font-family: "Genty";
  src: local("Genty"),
   url("../font/genty/GentyDemo-Regular.ttf") format("truetype");
   font-display: swap; 
 }

@font-face {
  font-family: "Saturday Night";
  src: local("Saturday Night"),
   url("../font/Saturday Night/Fonts/saturdaynight-script-regular.otf") format("opentype");
   font-display: swap; 
 }

@font-face {
  font-family: "Glacial Indifference";
  src: local("Glacia Indifference"),
   url("../font/glacial-indifference/GlacialIndifference-Regular.otf") format("opentype");
   font-display: swap; 
 }